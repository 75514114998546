import cat1 from "../assets/img/c1.png";
import cat2 from "../assets/img/c2.png";
import cat3 from "../assets/img/c3.png";
import cat4 from "../assets/img/c4.png";
import cat5 from "../assets/img/c5.png";
import cat6 from "../assets/img/c6.png";
import cat7 from "../assets/img/c7.png";
import cat8 from "../assets/img/c8.png";
import cat9 from "../assets/img/c9.png";



const subCategories = [
	{ name: "كل المنتجات", value: " " },
	{src: cat9, alt: "انتريهات", name: "انتريهات", value: "antreeh"},
	// { src: cat1, alt: "مكتب", name: "مكاتب", value: "office" },
	{ src: cat2, alt: "غرف نوم", name: "غرف نوم", value: "bedroom" },
	{
		src: cat3,
		alt: "غرف سفرة",
		name: "غرف سفرة",
		value: "dining-room",
	},
	{
		src: cat4,
		alt: "غرف اطفال",
		name: "غرف اطفال",
		value: "kids-room",
	},
	{ src: cat5, alt: "صالونات", name: "صالونات", value: "salon" },
	{ src: cat8, alt: "ركنات", name: "ركنات", value: "sofa" },
	{ src: cat6, alt: "نيش", name: "نيش", value: "cabinet" },
	{ src: cat7, alt: "طاولة", name: "طاولة", value: "table" },
];

export default subCategories;
